import revive_payload_client_d6hmW3HE2t from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vo258Uy6R1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_p7NT7FW7pH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IFFN5Nu59Y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.4_rollup@4.21.0_vite@5.4.3_@types+node@22.0.0_sass@1.78._6pbgmyx5vgfnrv67aj5433wp7m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_eKU343bRT1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zYHLMJLg8P from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PVKYm0Xjhb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1fWrtWJlVA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Z8RKOPYlHv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pwc2d3ihxlfobgcvjhbkldqzf4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_6wgqAnvSOL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_V5HI9C8bvu from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+api@1._eujaqbya5t6kpku42dyuuzdeuy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_hSY0UwDBSe from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import slideovers_QRfKd9fB94 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.4_rollup@4.21.0_vite@5.4.3_@types+node@22.0.0_sass@1.78.0_terser_hvdszdh4dghrhqvbvwghp7dztu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JPMumo4M8D from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.4_rollup@4.21.0_vite@5.4.3_@types+node@22.0.0_sass@1.78.0_terser_hvdszdh4dghrhqvbvwghp7dztu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_WvzjnSB0yT from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.4_rollup@4.21.0_vite@5.4.3_@types+node@22.0.0_sass@1.78.0_terser_hvdszdh4dghrhqvbvwghp7dztu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_pTD2V6LrrV from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MY1qeuk8Wt from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.4_rollup@4.21.0_vite@5.4.3_@types+node@22.0.0_sass@1.78.0_terse_kgsuzfkd3lfbhwxpez6wpac56m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_eiDu4Elcvr from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.2_ioredis@5.4.1_magicast@0.3.4_nuxt@3.13.1_@parcel+watcher@2.4.1_@types+no_shaeaciv575qtvmilkscq6zmvq/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import plugin_auto_pageviews_client_TtFinVEoCk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_d6hmW3HE2t,
  unhead_vo258Uy6R1,
  router_p7NT7FW7pH,
  _0_siteConfig_IFFN5Nu59Y,
  payload_client_eKU343bRT1,
  navigation_repaint_client_zYHLMJLg8P,
  check_outdated_build_client_PVKYm0Xjhb,
  chunk_reload_client_1fWrtWJlVA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Z8RKOPYlHv,
  plugin_client_6wgqAnvSOL,
  sentry_client_V5HI9C8bvu,
  sentry_client_config_o34nk2sJbg,
  plugin_client_hSY0UwDBSe,
  slideovers_QRfKd9fB94,
  modals_JPMumo4M8D,
  colors_WvzjnSB0yT,
  plugin_client_pTD2V6LrrV,
  plugin_MY1qeuk8Wt,
  documentDriven_eiDu4Elcvr,
  plugin_auto_pageviews_client_TtFinVEoCk
]